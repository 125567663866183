import React from 'react';

import { Grid, useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';
import {
  NormalizedAlgoliaListing,
  UtilityBillsValues,
} from 'ha/models/Listing/types';

import { getListingTypeTitle } from 'ha/modules/Listing/helpers';

import { ListingCardBillsLabelTranslations } from '../constants';
import { useListingSizeInfo } from '../hooks/useListingSizeInfo';
import { getAvailableDateLabel } from '../utils/getAvailableDateLabel';
import { getRoomLabel } from '../utils/getRoomLabel';

import { ListingCardInfoFooter } from './ListingCardInfoFooter';
import { ����VR��ƵSection } from './����VR��ƵSection';
import { ListingCardProps } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/1'),
    paddingTop: theme.utils.spacing('ref/spacing/2'),
  },
  section: {},

  propertyInfo: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  infoDivider: {
    color: theme.palette.mono.dark,

    marginInline: theme.utils.spacing('ref/spacing/1'),

    [theme.breakpoints.up('md')]: {
      marginInline: theme.utils.spacing('ref/spacing/2'),
    },
  },
}));

interface Props {
  room: NormalizedAlgoliaListing;
  queryParams: ListingCardProps['queryParams'];
}

const ListingCardInfoDivider = () => {
  const { classes } = useStyles();
  return <span className={classes.infoDivider}>•</span>;
};

const ListingCardPriceSuffix: React.FC<React.PropsWithChildren> = ({
  children,
  ...rest
}) => {
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Typography
      variant={isLargerThanMd ? 'body/lg-regular' : 'body/sm-regular'}
      color="secondary.light"
      {...rest}
    >
      {children}
    </Typography>
  );
};

const ListingCardInfoItem: React.FC<React.PropsWithChildren> = ({
  children,
  ...rest
}) => {
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Typography
      variant={isLargerThanMd ? 'body/md-regular' : 'body/sm-regular'}
      color="secondary"
      {...rest}
    >
      {children}
    </Typography>
  );
};

export const ListingCardInfo: React.FC<Props> = ({ room, queryParams }) => {
  const { T, formatDateTime } = useIntl();
  const { classes } = useStyles();

  const {
    propertyType,
    bedroomCount,
    currentOccupancy,
    freePlaces,
    advertiserMoveInOverallRating,
    advertiserMoveInReviewCount,
    country,
    price,
    currency,
  } = room;

  const { isAptSizePresented, listingSize } = useListingSizeInfo({ ...room });

  const listingKindLabel = getListingTypeTitle(T, propertyType);

  const listingDatesLabel = getAvailableDateLabel(T, formatDateTime, room);

  const formattedPrice = Math.floor(price / 100).toString();

  const roomLabel = getRoomLabel(
    T,
    propertyType,
    bedroomCount,
    currentOccupancy,
    freePlaces,
  );

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Grid
          container
          columnGap={1}
          alignItems="center"
          data-test-locator="ListingCardInfoPrice"
        >
          <meta itemProp="price" content={formattedPrice} />
          <meta itemProp="priceCurrency" content={currency} />
          <����VR��ƵSection room={room} queryParams={queryParams} />
          <ListingCardPriceSuffix>
            {T('search.listing_card.price.per_month')}
          </ListingCardPriceSuffix>
          <ListingCardPriceSuffix>
            {`(${T(
              ListingCardBillsLabelTranslations[
                room.utilities || UtilityBillsValues.EXCLUDED
              ],
            )})`}
          </ListingCardPriceSuffix>
        </Grid>
      </div>
      <div className={classes.section}>
        <div
          className={classes.propertyInfo}
          data-test-locator="ListingCardPropertyInfo"
        >
          <ListingCardInfoItem>
            {listingKindLabel} <ListingCardInfoDivider />
          </ListingCardInfoItem>

          {isAptSizePresented && (
            <ListingCardInfoItem>
              {listingSize}
              <ListingCardInfoDivider />
            </ListingCardInfoItem>
          )}

          {roomLabel && (
            <ListingCardInfoItem data-test-locator="ListingCardInfo/RoomLabel">
              {roomLabel}
              <ListingCardInfoDivider />
            </ListingCardInfoItem>
          )}

          <ListingCardInfoItem>
            {listingDatesLabel && listingDatesLabel}
          </ListingCardInfoItem>
        </div>
      </div>
      <div className={classes.section}>
        <ListingCardInfoFooter
          room={room}
          country={country}
          moveInOverallRating={advertiserMoveInOverallRating}
          moveInRatingsCount={advertiserMoveInReviewCount}
        />
      </div>
    </div>
  );
};
