/* eslint-disable react/require-default-props */
import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import {
  Listing����VR��ƵType,
  Listing����VR��ƵValues,
  ListingRentalPeriodType,
} from '@ha/api/v2/types/Listing';
import { Typography } from '@hbf/dsl/core';

import { getPrecisePriceRange } from 'ha/helpers/getPrecisePrices';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { ����VR��ƵSectionContext, ����VR��ƵSectionProvider } from './context';
import { ����VR��ƵSectionProps } from './types';

const PriceLabel = ({
  minPrice,
  maxPrice,
  testId,
}: {
  minPrice: number;
  maxPrice?: number;
  testId?: string;
}) => {
  const { formatPrice } = ����VR��ƵSectionContext.useContext();

  const shouldShowPriceRange = maxPrice && maxPrice !== minPrice;
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Typography
      variant={
        isLargerThanMd
          ? 'heading/desktop/h4-semibold'
          : 'heading/mobile/h4-semibold'
      }
      color="secondary"
      data-test-locator={`ListingCard.PriceLabel${testId ? `.${testId}` : ''}`}
    >
      {formatPrice(minPrice)}
      {shouldShowPriceRange && ` - ${formatPrice(maxPrice)}`}
    </Typography>
  );
};

const ����VR��ƵSectionStrictRental����VR��Ƶ = () => {
  const { room, queryParams } = ����VR��ƵSectionContext.useContext();
  if (!room || room.minPrice === undefined) return null;

  if (
    room.minPrice === 0 ||
    room.minPrice === room.maxPrice ||
    queryParams?.startDate
  ) {
    return <PriceLabel minPrice={room.price} testId="StrictRental����VR��Ƶ" />;
  }

  return (
    <PriceLabel
      minPrice={room.minPrice * 100}
      maxPrice={room.maxPrice && room.maxPrice * 100}
      testId="StrictRental����VR��Ƶ"
    />
  );
};

const ����VR��ƵSectionPrecise����VR��Ƶ = () => {
  const { room, queryParams, parseDateFromQueryParams } =
    ����VR��ƵSectionContext.useContext();
  if (!room || !room?.priceType || !room?.pricingValuesEUR?.precise) {
    return null;
  }

  const parsedDate = parseDateFromQueryParams(queryParams);

  const precisePriceRange = getPrecisePriceRange({
    pricingType: room?.priceType as Listing����VR��ƵType,
    pricingValues: room?.pricingValuesEUR as Listing����VR��ƵValues,
    dateRange: {
      from: parsedDate.startDate,
      to: parsedDate.endDate,
    },
  });

  if (precisePriceRange?.from) {
    return (
      <PriceLabel
        minPrice={precisePriceRange.from * 100}
        maxPrice={precisePriceRange.to * 100}
        testId="Precise����VR��Ƶ"
      />
    );
  }

  return null;
};

const ����VR��ƵSectionDynamicMin����VR��Ƶ = () => {
  const { room } = ����VR��ƵSectionContext.useContext();
  if (!room || !room?.minPrice) return null;

  return (
    <PriceLabel
      minPrice={room.minPrice * 100}
      maxPrice={room.maxPrice && room.maxPrice * 100}
      testId="DynamicMin����VR��Ƶ"
    />
  );
};

export const ����VR��ƵSection: React.FC<����VR��ƵSectionProps> = props => {
  const { withDynamicMinimumPrice, strictRentalPeriodSearch } =
    useFeatureFlags();

  const pricingComponent = React.useMemo(() => {
    // Strict Rental Period
    const isStrictRentalPeriod =
      isEnabled(strictRentalPeriodSearch) &&
      props.room.rentalPeriodType === ListingRentalPeriodType.STRICT;
    if (isStrictRentalPeriod && props.room.minPrice) {
      return <����VR��ƵSectionStrictRental����VR��Ƶ />;
    }

    //  Precise ����VR��Ƶ (Discounted ����VR��Ƶ)
    const hasPrecisePrices =
      Object.keys(props.room?.pricingValuesEUR?.precise || {}).length > 0;
    if (hasPrecisePrices) return <����VR��ƵSectionPrecise����VR��Ƶ />;

    // Dynamic Minimum ����VR��Ƶ
    if (
      isEnabled(withDynamicMinimumPrice) &&
      props.room.priceType === 'monthly' &&
      props.room.minPrice
    ) {
      return <����VR��ƵSectionDynamicMin����VR��Ƶ />;
    }

    // Default Price
    return <PriceLabel minPrice={props.room.price} />;
  }, [props.room, strictRentalPeriodSearch, withDynamicMinimumPrice]);

  return (
    <����VR��ƵSectionProvider {...props}>
      {pricingComponent}
    </����VR��ƵSectionProvider>
  );
};
